import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    VContainer,
    [
      _c("HeadbarPage", {
        attrs: { title: "Títulos > Alterar Agrupador em Massa" }
      }),
      _c("UploadEmMassa", {
        attrs: {
          title: "Alterar Agrupador de títulos em lote",
          textAreaLabel:
            "Cole aqui os títulos que deseja solicitar a alteração de Agrupador",
          textAreaHint:
            "Insira nesse campo todos os títulos que deseja buscar separando por virgula, por exemplo: 477977/1, 721603/6, 755202/5",
          instrucaoUpload:
            "Utilize o campo abaixo para fazer upload de arquivo com os títulos em massa para alteração de Agrupador",
          textAreaPlaceholder:
            "Cole aqui os títulos que deseja solicitar a alteração de Agrupador",
          dica: ""
        },
        on: { buscarTitulos: _vm.buscarTitulos }
      }),
      _c("TableWithSelections", {
        ref: "tableWithSelections",
        attrs: {
          titulos: _vm.titulos,
          totalTitulos: _vm.totalTitulos,
          loading: _vm.loading
        },
        on: { selecionados: _vm.setSelecionados }
      }),
      _vm.showChild
        ? _c("CardConfirmacaoEnvioEmMassa", {
            key: _vm.childKey,
            ref: "cardModal",
            attrs: {
              selecionados: _vm.selecionados,
              avisoRetorno: _vm.avisoRetorno,
              loadingFiltros: _vm.loadingFiltros,
              resultadoCheck: _vm.resultadoCheck,
              titulosEnviadosCheck: _vm.titulosEnviadosCheck,
              type: "Agrupador",
              agrupadores: _vm.agrupadores
            },
            on: { enviar: _vm.alteracaoAgrupadorMassa }
          })
        : _vm._e(),
      _vm.snackbar
        ? _c("Snackbar", {
            attrs: {
              mostrarSnackbar: _vm.snackbar,
              corSnackbar: _vm.colorSnackbar,
              mensagemSnackbar: _vm.mensagemSnackbar
            },
            on: {
              fecharSnackbar: function($event) {
                _vm.snackbar = false
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }